import React, { useState, useEffect } from "react";
import Header from "../../organisms/Header";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../organisms/Sidebar";
import Footer from "../../organisms/Footer";
import Dialer from "../../organisms/Dialer";
import { toast, ToastContainer } from "react-toastify";
import DotsLoader from "../../atoms/DotsLoader";
import {
  setCampaign,
  setColor,
  setCurrentCall,
  setDialerPhone,
  setDialerPhoneD,
  setDispoSelectStop,
  setExt,
  setInGroup,
  setStatus,
  setTimer,
} from "../../../features/dialerSlice";
import Backdrop from "../../atoms/Backdrop";
import Misscalls from "../../organisms/Misscall";
import { sendIframeMsg } from "../../../util/iFrame";
import jwtDecode from "jwt-decode";
import CustomModal from "./ExpireModal";
import { logout, logoutUser } from "../../../features/authSlice";
import SmsCenter from "../../organisms/SmsCenter";
import { FaTimes } from "react-icons/fa";
import { BiSolidPhoneCall } from "react-icons/bi";
const Layout = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  const { currentCall, status } = useSelector(
    (state) => state.dialer
  );
  const [show_navlabel, setShowNavbar] = useState(true);
  const [isExpiring, setExpriring] = useState(false);
  const [show_mobilenav, setShow_mobilenav] = useState(false);
  const [show_nav, setShow_nav] = useState(true);
  const [isForm, setIsForm] = useState(false);
  const [isDialer, setIsDialer] = useState("hidden");
  const [logingOut, setLogingOut] = useState(false);
  const [isSMS, setIsSMS] = useState(false);
  const [isMisscall, setIsMisscall] = useState(false);
  const [isRecordings, setIsRecordings] = useState(false);
  const [isDroping, setIsDroping] = useState(false);
  const [isDroped, setIsDroped] = useState(false);
  const [isDropingOut, setIsDropingOut] = useState(false);
  const [misscallDate, setMisscallDate] = React.useState(new Date());
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const toggleNavbar = () => {
    setShowNavbar(!show_navlabel);
    setShow_nav(!show_nav);
  };

  // React.useEffect(() => {
  //   if (userPermission("Calendar") && user) {
  //     if (!socket.connected) {
  //       socket.connect();
  //     }
  //     const setupEventListeners = () => {
  //       socket.on("eventReminder", handleNewMessage);
  //     };

  //     if (socket.connected) {
  //       setupEventListeners();
  //     } else {
  //       socket.on("connect", setupEventListeners);
  //     }
  //     return () => {
  //       socket.off("eventReminder", handleNewMessage);
  //       socket.off("connect", setupEventListeners);
  //       if (socket.connected) {
  //         socket.disconnect();
  //       }
  //     };
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);

  useEffect(() => {
    if (location?.pathname.includes("client")) {
      setIsForm(true);
    } else {
      setIsForm(false);
    }
  }, [location]);
  useEffect(() => {
    if (!user) {
      setIsDialer("hidden");
    }
    // eslint-disable-next-line
  }, [user]);
  const checkTokenExpiry = () => {
    const refreshToken = localStorage.getItem("refreshToken");

    if (!refreshToken) {
      dispatch(logoutUser());
      dispatch(setStatus(""));
      dispatch(setTimer(""));
      return;
    }
    const jwt = jwtDecode(refreshToken);
    const currentTime = Math.floor(Date.now() / 1000);
    const timeLeft = jwt.exp - currentTime;
    if (timeLeft > 0 && timeLeft <= 120) {
      setExpriring(true);
    } else if (timeLeft <= 0) {
      setExpriring(false);
      alert("Your session has expired. Please log in again.");
      dispatch(logoutUser());
      dispatch(setStatus(""));
      dispatch(setTimer(""));
    }
  };
  useEffect(() => {
    checkTokenExpiry();
    const interval = setInterval(checkTokenExpiry, 10000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [hasNewMessage, setHasNewMessage] = useState(false);
  useEffect(() => {
    if (hasNewMessage) {
      setHasNewMessage(false);
    }
  }, [hasNewMessage]);
  const receiveMessage = (event) => {
    if (event?.data?.action === "ManualDialNext") {
      return null;
      // if (event.data.phone_number) {
      //   dispatch(
      //     setDialerPhone({
      //       phone: event.data.phone_number,
      //       callType: "Outgoing",
      //       dialer_lead_id: event?.data?.lead_id,
      //       vicidial_id: event?.data?.vicidial_id,
      //       event_data: event?.data,
      //     })
      //   );
      //   dispatch(
      //     setDialerPhoneD({
      //       phone: event.data.phone_number,
      //       callType: "Outgoing",
      //     })
      //   );
      //   if (!event.data.api_click) {
      //     navigate("/leads/lead_AddEdit");
      //   }
      // }
    } else if (event?.data?.action === "check_for_auto_incoming") {
      if (event?.data?.phone_number) {
        dispatch(
          setDialerPhone({
            phone: event.data.phone_number,
            callType: "Incoming",
            dialer_lead_id: event?.data?.lead_id,
            vicidial_id: event?.data?.vicidial_id,
            did_pattern: event?.data?.did_pattern,
            did_description: event?.data?.did_description,
            event_data: event?.data,
          })
        );
        dispatch(
          setDialerPhoneD({
            phone: event.data.phone_number,
            callType: "Incoming",
            did_pattern: event?.data?.did_pattern,
            did_description: event?.data?.did_description,
          })
        );
        dispatch(setDispoSelectStop(false));
        if (!event.data.api_click) {
          navigate("/leads/lead_AddEdit");
        }
      }
    } else if (event.data.action === "agent_state_start") {
      if (event?.data?.status) {
        dispatch(setStatus(event.data.status));
        dispatch(setExt(event.data.phone_login));
      }
      if (event?.data?.campaign) {
        dispatch(setCampaign(event.data.campaign));
      }
      if (event?.data?.HHMMSS) {
        dispatch(setTimer(event.data.HHMMSS));
      }
      if (event?.data?.status === "logout") {
        dispatch(setStatus(""));
        dispatch(setExt(""));
        dispatch(setTimer(""));
        dispatch(setCampaign(""));
      }
      if (event?.data?.status === "logged_out") {
        dispatch(setStatus(""));
        dispatch(setExt(""));
        dispatch(setTimer(""));
      }
      if (event?.data?.classColor) {
        dispatch(setColor(event.data.classColor));
      }
    }
    if (event?.data?.action === "DispoSelectStop") {
      dispatch(setDispoSelectStop(event.data.checked));
    }
    if (event?.data?.action === "DeactivateDOlDSessioNSpan") {
      sendIframeMsg({
        action: "DeactivateDOlDSessioNSpan",
        user: user?.dialer_user_info?.dialer_user,
        pass: user?.dialer_user_info?.dialer_pass,
      });
    }
    if (event?.data?.action === "InGroup") {
      dispatch(setInGroup(event?.data?.value));
    }
  };
  const logoutHandler = async () => {
    await dispatch(logout());
    sendIframeMsg({
      action: "logout",
      user: user?.dialer_user_info?.dialer_user,
      pass: user?.dialer_user_info?.dialer_pass,
    });
    setExpriring(false);
    const timer = setTimeout(() => {
      dispatch(logoutUser());
      dispatch(setStatus(""));
      dispatch(setTimer(""));
    }, 0);
    return () => clearTimeout(timer);
  };
  window.addEventListener("message", receiveMessage);
  const callinqueuegrab = (id) => {
    if (status !== "PAUSE")
      return toast.info("Agent must be on pause to grab this call");
    sendIframeMsg({
      action: "callinqueuegrab",
      user: user?.dialer_user_info?.dialer_user,
      pass: user?.dialer_user_info?.dialer_pass,
      value: id,
    });
    dispatch(setCurrentCall(null));
  };
  const handleNewCallModal = () => {
    sendIframeMsg({
      action: "show_calls_in_queue",
      user: user?.dialer_user_info?.dialer_user,
      pass: user?.dialer_user_info?.dialer_pass,
      value: "HIDE",
    });
    dispatch(setCurrentCall(null));
  };
  const CallNotificationModal = () => {
    return (
      <div className="z-[10000] absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black bg-opacity-50">
        <div className="bg-white max-w-[400px] text-center rounded-md">
          <div className="flex justify-end w-full p-2">
            <button
              className="mb-2 h-8 w-8 rounded-full bg-menu text-white flex justify-center items-center"
              onClick={handleNewCallModal}
            >
              <FaTimes size={20} />
            </button>
          </div>
          <div className="flex flex-col gap-y-5 !px-4 pb-4 pt-2">
            <div className="flex w-full justify-center text-menu">
              <BiSolidPhoneCall size={96} />
            </div>
            <strong className="text-3xl font-pop">
              You have a new call in your queue from{" "}
            </strong>

            <strong className="mx-1 text-primary-100 text-2xl font-pop">
              {currentCall?.phone_number || ""}
            </strong>
            <span className="font-semibold text-xl">
              Please check and respond as soon as possible.
            </span>
            <div className="flex w-full justify-center">
              <button
                type="button"
                className="bg-primary-100 text-lg font-bold !px-3 py-2 rounded w-fit text-white"
                onClick={() => callinqueuegrab(currentCall.call_id)}
              >
                Take call
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const showFooter = true;
  return (
    <>
      <style>{`
        @media (min-width: 768px) {
          .middle-cont {
            margin-left: 235px;
            width: calc(100% - 235px);
            height: calc(100vh - 28px);
          }
        }
        @media (max-width: 768px) {
          .middle-cont {
            width: 100%;
          }
        }
      `}</style>
      {user && isForm ? (
        children
      ) : (
        <>
          {user && !isForm && (
            <>
              <div className="fixed top-0 w-full z-10">
                <Header toggleNavbar={setShow_nav} show_nav={show_nav} />
              </div>

              <div className="flex flex-nowrap relative overflow-y-hidden overflow-hidden max-w-[100vw] bg-white">
                <div className="px-0 ">
                  <div
                    className={`transition-all ease-in-out delay-150 fixed w-[235px]  ${
                      show_nav
                        ? "left-[-235px] md:left-0"
                        : "left-0 md:left-[-235px]"
                    }`}
                  >
                    <Sidebar
                      toggleNavbar={toggleNavbar}
                      show_navlabel={show_navlabel}
                      setIsForm={setIsForm}
                      setLogingOut={setLogingOut}
                      isDroping={isDroping}
                      isDroped={isDroped}
                      isDropingOut={isDropingOut}
                      isDialer={isDialer}
                      showNav={show_nav}
                    />
                  </div>
                </div>

                <div
                  className={`flex justify-center transition-all ease-in-out delay-150 h-screen w-full ${
                    showFooter ? "!pb-5" : "pb-2"
                  } ${
                    show_nav
                      ? "ml-0 md:!ml-[235px] md:w-[calc(100%-235px)]"
                      : "ml-[235px] md:ml-0"
                  }`}
                >
                  {logingOut && <DotsLoader />}
                  <div
                    className={`w-full ${
                      user.company_name === "Verified CRM" && show_nav
                        ? "pt-[63px]"
                        : "pt-[63px]"
                    } ${showFooter ? "mb-[20px]" : ""}`}
                    style={{
                      overflowY: "auto",
                    }}
                  >
                    <div className="mx-2">
                      {isDropingOut && (
                        <div className="absolute left-[calc(15vw)] top-0 bottom-0 right-0 !bg-[rgba(213,213,213,0.5)] z-[99]">
                          <div className="bg-[#f7f7f7] h-[300px] w-[210px] absolute bottom-0.5 ml-1 shadow-[0_0.125rem_0.5rem_rgb(0_0_0_/30%),_0_0.0625rem_0.125rem_rgb(0_0_0_/20%)] rounded-t-md" />
                        </div>
                      )}
                      {children}
                    </div>
                    {isSMS && (
                      <SmsCenter
                        setIsSMSCenter={setIsSMS}
                        isSMSCenter={isSMS}
                      />
                    )}
                    {isMisscall && (
                      <Misscalls
                        setIsMisscall={setIsMisscall}
                        isMisscall={isMisscall}
                        setMisscallDate={setMisscallDate}
                        misscallDate={misscallDate}
                      />
                    )}
                    {showFooter ? (
                      <div
                        className={`bg-white transition-all ease-in-out delay-150 h-10 fixed bottom-0  box-border text-xs border-[#b2b2b2] shadow-[0_0_6px_rgb(0_0_0_/20%)] pl-0 ${
                          show_nav
                            ? "w-full md:w-[calc(100%-220px)]"
                            : "w-full md:w-full"
                        }`}
                      >
                        <Footer
                          toggleNavbar={toggleNavbar}
                          setIsDialer={setIsDialer}
                          setIsSMS={setIsSMS}
                          isSMS={isSMS}
                          setIsMisscall={setIsMisscall}
                          isMisscall={isMisscall}
                          isDialer={isDialer}
                          setIsRecordings={setIsRecordings}
                          isRecordings={isRecordings}
                          setMisscallDate={setMisscallDate}
                          misscallDate={misscallDate}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              {show_mobilenav && (
                <>
                  <Backdrop onClick={() => setShow_mobilenav(false)} />
                  <div
                    className={[
                      "bottom_navbar col-md-2 px-0 block md:hidden absolute h-full ",
                    ].join(" ")}
                    style={{
                      backgroundColor: "#fff",
                      zIndex: 100,
                      width: "218px",
                      top: 0,
                      transition: "1s",
                    }}
                  >
                    <Sidebar
                      toggleNavbar={toggleNavbar}
                      show_navlabel={show_navlabel}
                      setIsForm={setIsForm}
                      setLogingOut={setLogingOut}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
      {!user && !isForm ? children : !user && isForm && children}
      <Dialer
        modalTitle="SOFTPHONE"
        isDialer={isDialer}
        setIsDialer={setIsDialer}
        setIsDroping={setIsDroping}
        isDroping={isDroping}
        setIsDroped={setIsDroped}
        isDroped={isDroped}
        setIsDropingOut={setIsDropingOut}
      />
      {isExpiring && user ? (
        <CustomModal
          logoutHandler={logoutHandler}
          onClose={() => setExpriring(false)}
        />
      ) : null}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {currentCall ? <CallNotificationModal /> : null}
    </>
  );
};

export default Layout;
