import api from "./api";

export const getUsers = async (data) => {
  return await api.post(`/api/users/report`, data);
};
export const getUsersDrd = async (data) => {
  return await api.post(`/api/users/drd`, data);
};
export const getVerifiedUsersDrd = async (data) => {
  return await api.get(`/api/users/verifiedCrmUsers`);
};
export const getTech = async (data) => {
  return await api.post(`/api/users/tech`, data);
};
export const getVendorsForJobs = async (data) => {
  return await api.post(`/api/cms/vendor_by_radius/404`, data);
};
export const createUser = async (values) => {
  return await api.post("/api/users/new", values);
};

export const updateUser = async (userId, values) => {
  return await api.put(`/api/users/new/${userId}`, values);
};

export const deleteUser = async (id) => {
  return await api.delete("/api/users", { data: { id } });
};
export const getCRMVendorsDrd = async () => {
  return await api.get(`/api/cms/vendor/drd`);
};

export const updateUserPassword = async ({ id, password }) => {
  return await api.post(`/api/users/password/${id}`, { password: password });
};
