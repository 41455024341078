import React from 'react';
import { TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

const TimePickerField = ({
  name,
  label,
  formik,
  placeholder,
  value,
  errors,
  className,
  onChange,
  rootClass = "",
}) => {
  return (
    <div className={`flex flex-col ${rootClass}`}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          label={label ? label : placeholder}
          value={formik?.values[name] || formik?.values[name] === 0
            ? dayjs(`2025-02-04T${formik?.values[name]}:00`)
            : dayjs(`2025-02-04T${value}:00`) || value === 0
              ? value === "" ? null : dayjs(`2025-02-04T${value}:00`)
              : null
          }
          onChange={onChange ? onChange : formik?.handleChange}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
      </LocalizationProvider>
      {formik?.errors[name] && formik?.touched[name] && (
        <small
          style={{
            fontSize: 12,
            marginTop: -5,
            paddingLeft: 16,
            color: "#D32F2F",
          }}
        >
          {formik.errors[name]}
        </small>
      )}
      {errors !== undefined &&
        errors?.length > 0 &&
        errors?.map((error, index) => {
          return (
            <>
              {error[name] && (
                <small
                  style={{
                    fontSize: 12,
                    marginTop: -5,
                    paddingLeft: 16,
                    color: "#D32F2F",
                  }}
                  key={index}
                >
                  {error[name]
                    .replaceAll(`"${name}"`, label)
                    .replaceAll(`${name}`, label)}
                </small>
              )}
            </>
          );
        })}
      {errors !== undefined && typeof errors === "object" && (
        <>
          {errors[name] && (
            <small
              style={{
                fontSize: 12,
                marginTop: 0,
                paddingLeft: 16,
                color: "#D32F2F",
                background: "transparent",
              }}
            >
              {errors[name][0]}
            </small>
          )}
        </>
      )}
    </div>
  );
};

export default TimePickerField;
