import api from "./api";

export const getUserGroups = async () => {
  return await api.get(`/api/group/parentGroups`);
};
export const createUserGroup = async (values) => {
  return await api.post(`/api/group`, values);
};

export const updateUserGroup = async (values) => {
  const payload = { ...values };
  delete payload.id;
  return await api.put(`/api/group/${values.id}`, payload);
};

export const deleteUserGroup = async (id) => {
  return await api.delete(`/api/group/${id}`);
};
