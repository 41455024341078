import api from "./api";

export const getLeadStatus = async () => {
  return await api.get("/api/leadStatus");
};
export const getLeadStatusReport = async (data) => {
  return await api.post("/api/leadStatus/report", data);
};
export const createLeadStatus = async (data) => {
  return await api.post("/api/leadStatus", data);
};

export const updateLeadStatus = async (data) => {
  const payload={...data}
  delete payload.id
  return await api.put(`/api/leadStatus/${data?.id}`, payload);
};

export const updateLeadPriority = async (data) => {
  const payload={...data}
  delete payload.id
  return await api.put(`/api/leadStatus/update_priority/${data?.id}`, payload);
};

export const deleteLeadStatus = async (id) => {
  return await api.delete(`/api/leadStatus/${id}`);
};
